<template>
  <v-container fluid class="grey lighten-3">

    <v-card class="mb-5">

        <v-tabs v-model="tab" @change="tabChanged">
          <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <RefreshData v-if="tab == 0"></RefreshData>
          </v-tab-item>
          <v-tab-item>
            <FindNewKeywords v-if="tab == 1"></FindNewKeywords>
          </v-tab-item>
          <v-tab-item>
            <ViewRefreshLog v-if="tab == 2"></ViewRefreshLog>
          </v-tab-item>
          <v-tab-item>
            <ManageBlacklist v-if="tab == 3"></ManageBlacklist>
          </v-tab-item>
        </v-tabs-items>

    </v-card>

  </v-container>
</template>

<script>
export default {
  name: 'KeywordCollector',

  data () {
    return {
      tab: null,
      items: ['Refresh data', 'Find new keywords', 'View refresh log', 'Manage blacklist'],
      lsTab: 'kwCollector_tab',
    }
  },

  components: {
    FindNewKeywords: () => import('@/components/keywordCollector/FindNewKeywords.vue'),
    ManageBlacklist: () => import('@/components/keywordCollector/ManageBlacklist.vue'),
    RefreshData: () => import('@/components/keywordCollector/RefreshData.vue'),
    ViewRefreshLog: () => import('@/components/keywordCollector/ViewRefreshLog.vue'),
  },

  methods: {
    tabChanged () {
      localStorage.setItem(this.lsTab, JSON.stringify(this.tab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsTab)) {
      this.tab = JSON.parse(localStorage.getItem(this.lsTab))
    }
  },
}
</script>

<style scoped>
</style>